import { diffArrays } from 'diff'

export const diffWordsArray = (oldWordStr, newWordStr) => {
  const oldWordArray = oldWordStr
    .split(/\s+/g)
    .map((str) => ({
      value: str,
      pureValue: str
        .replace(/[-,.?:;'"!`]+$/, '')
        .replace(/^[-,.?:;'"!`]+/, '')
        .replace(/[‘’]/gi, "'")
        .toLowerCase(),
      added: false,
      removed: false
    }))
    .map((m) => m.pureValue)

  const newWordArray = newWordStr.split(/\s+/g).map((str) => ({
    value: str,
    pureValue: str
      .replace(/[-,.?:;'"!`]+$/, '')
      .replace(/^[-,.?:;'"!`]+/, '')
      .replace(/[‘’]/gi, "'")
      .toLowerCase(),
    added: false,
    removed: false
  }))
  const newWordStrArray = newWordArray.map((m) => m.pureValue)

  let valueIndex = 0
  return diffArrays(oldWordArray, newWordStrArray)
    .reduce(
      (list, item) =>
        list.concat(item.value.map((value) => ({ ...item, value }))),
      []
    )
    .map((item, index) => {
      const findItem = newWordArray.find((m) => m.pureValue === item.value)
      if (findItem) {
        valueIndex = index
      }
      return {
        ...item,
        value: (findItem || {}).value || item.value
      }
    })
}
